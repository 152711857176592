import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CanDeactivateGuard } from './shared/components/can-deactivate/can-deactivate.guard';
import { UnauthorizedPageComponent } from './shared/components/unauthorized-page/unauthorized-page.component';
import { AppPermissionGuard } from './app-permission.guard';
import { UnauthorizedApplicationComponent } from './shared/components/unauthorized-application/unauthorized-application.component';
import { AppAuthorizedGuard } from './app-authorized.guard.';
//import { AdalGuard } from 'adal-angular4';


const routes: Routes = [

  { path: '', loadChildren:()=>import('./pages/home/home.module').then(x=>x.HomeModule), canActivate: [AppPermissionGuard] },
  { path: 'home', loadChildren:()=>import('./pages/home/home.module').then(x=>x.HomeModule), canActivate: [AppPermissionGuard] },


  { path: 'search-material', loadChildren:()=>import('./pages/search-material/search-material.module').then(x=>x.SearchMaterialModule), canActivate: [AppPermissionGuard] },
  { path: 'multiple-items-search', loadChildren:()=>import('./pages/multiple-items-search/multiple-items-search.module').then(x=>x.MultipleItemsSearchModule), canActivate: [AppPermissionGuard] },
  { path: 'request-new-jde', loadChildren:()=>import('./pages/request-new-jde/request-new-jde.module').then(x=>x.RequestNewJdeModule), canActivate: [AppPermissionGuard] },

  { path: 'search-mto',  loadChildren:()=>import('./pages/search-mto/search-mto.module').then(x=>x.SearchMTOModule), canActivate: [AppPermissionGuard] },

  { path: 'create-mto/:id', loadChildren:()=>import('./pages/create-mto/create-mto.module').then(x=>x.CreateMtoModule), canDeactivate: [CanDeactivateGuard], canActivate: [AppPermissionGuard] },
  { path: 'create-mto/:id/:action', loadChildren:()=>import('./pages/create-mto/create-mto.module').then(x=>x.CreateMtoModule), canDeactivate: [CanDeactivateGuard], canActivate: [AppPermissionGuard] },
  { path: 'create-mto', loadChildren:()=>import('./pages/create-mto/create-mto.module').then(x=>x.CreateMtoModule), canDeactivate: [CanDeactivateGuard], canActivate: [AppPermissionGuard] },

  { path: 'master', loadChildren:()=>import('./pages/master/master.module').then(x=>x.MasterModule) },

  //{ path: 'setup-user', loadChildren: './pages/master/setup-user/setup-user.module#SetupUserModule', canActivate: [AppPermissionGuard] },
  //{ path: 'setup-role', loadChildren: './pages/master/setup-role/setup-role.module#SetupRoleModule', canActivate: [AppPermissionGuard] },
  //{ path: 'supplier-material-management', loadChildren: './pages/master/supplier-material-management/supplier-material-management.module#SupplierMaterialManagementModule', canActivate: [AppPermissionGuard] },
  //{ path: 'supplier-classification', loadChildren: './pages/master/supplier-classification/supplier.classification.module#SupplierClassificationModule', canActivate: [AppPermissionGuard] },
  //{ path: 'setup-mapping', loadChildren: './pages/master/setup-mapping/setup-mapping.module#SetupMappingModule', canActivate: [AppPermissionGuard] },
  // { path: 'setup-shipto', loadChildren: './pages/master/setup-ship-to/setup.ship.to.module#SetupShipToModule', canActivate: [AppPermissionGuard] },


  { path: 'suggest-mto', loadChildren:()=>import('./pages/suggest-mto/suggest-mto.module').then(x=>x.SuggestMTOModule), canActivate: [AppPermissionGuard] },
  { path: 'material-transfer-approval', loadChildren:()=>import('./pages/material-transfer-approval/material-transfer-approval.module').then(x=>x.MaterialTransferApprovalModule), canActivate: [AppPermissionGuard] },


  { path: 'material-reserve-form', loadChildren:()=>import('./pages/material-reserve-form/material-reserve-form.module').then(x=>x.MaterialReserveFormModule), canActivate: [AppPermissionGuard] },
  { path: 'material-reserve-form/:date',  loadChildren:()=>import('./pages/material-reserve-form/material-reserve-form.module').then(x=>x.MaterialReserveFormModule), canActivate: [AppPermissionGuard] },

  { path: 'material-call-off/:mconumber', loadChildren:()=>import('./pages/material-call-off/material-call-off.module').then(x=>x.MaterialCallOffModule), canActivate: [AppPermissionGuard] },
  { path: 'material-call-off/:fabjobnumber/:mconumber', loadChildren:()=>import('./pages/material-call-off/material-call-off.module').then(x=>x.MaterialCallOffModule), canActivate: [AppPermissionGuard] },
  { path: 'material-call-off', loadChildren:()=>import('./pages/material-call-off/material-call-off.module').then(x=>x.MaterialCallOffModule), canActivate: [AppPermissionGuard] },
  { path: 'mmp-issue-log', loadChildren:()=>import('./pages/mmp-issue-log/mmp-issue-log.module').then(x=>x.MMPIssueLogModule), canActivate: [AppPermissionGuard] },

  { path: 'bmc-monthly-pr', loadChildren:()=>import('./pages/bmc-monthly-pr/bmc-monthly-pr.module').then(x=>x.BMCMonthlyPRModule), canActivate: [AppPermissionGuard] },

  { path: 'import-pr', loadChildren:()=>import('./pages/import-pr/import-pr.module').then(x=>x.ImportPrModule), canActivate: [AppPermissionGuard] },
  { path: 'assign-location',  loadChildren:()=>import('./pages/assign-location/assign-location.module').then(x=>x.AssignLocationModule), canActivate: [AppPermissionGuard] },
  { path: 'assign-location/:reserveno', loadChildren:()=>import('./pages/assign-location/assign-location.module').then(x=>x.AssignLocationModule), canActivate: [AppPermissionGuard] },
  { path: 'current-inventory',  loadChildren:()=>import('./pages/current-inventory/current-inventory.module').then(x=>x.CurrentInventoryModule), canActivate: [AppPermissionGuard] },
  { path: 'material-transfer-relocation', loadChildren:()=>import('./pages/material-transfer-re-location/material-transfer-re-location.module').then(x=>x.MaterialTransferReLocationModule), canActivate: [AppPermissionGuard] },
  { path: 'inventory-summary', loadChildren:()=>import('./pages/inventory-summary/inventory-summary.module').then(x=>x.InventorySummaryModule), canActivate: [AppPermissionGuard] },
  { path: 'inventory-summary-mmp', loadChildren:()=>import('./pages/inventory-summary-mmp/inventory-summary-mmp.module').then(x=>x.InventorySummaryMMPModule), canActivate: [AppPermissionGuard] },
  { path: 'material-transfer-relocation/:No', loadChildren:()=>import('./pages/material-transfer-re-location/material-transfer-re-location.module').then(x=>x.MaterialTransferReLocationModule), canActivate: [AppPermissionGuard] },



  { path: 'rmr-detail/:id', loadChildren:()=>import('./pages/rmr-detail/rmr-detail.module').then(x=>x.RmrDetailModule), canActivate: [AppPermissionGuard] },
  { path: 'rmr-detail',  loadChildren:()=>import('./pages/rmr-detail/rmr-detail.module').then(x=>x.RmrDetailModule), canActivate: [AppPermissionGuard] },

  { path: 'search-rmr', loadChildren:()=>import('./pages/search-rmr/search-rmr.module').then(x=>x.SearchRmrModule), canActivate: [AppPermissionGuard] },
  { path: 'search-rmr/:date', loadChildren:()=>import('./pages/search-rmr/search-rmr.module').then(x=>x.SearchRmrModule), canActivate: [AppPermissionGuard] },
  { path: 'search-rmr/:status', loadChildren:()=>import('./pages/search-rmr/search-rmr.module').then(x=>x.SearchRmrModule), canActivate: [AppPermissionGuard] },

  { path: 'search-for-transfer',  loadChildren:()=>import('./pages/search-for-transfer/search-for-transfer.module').then(x=>x.SearchForTransferModule), canActivate: [AppPermissionGuard] },

  { path: 'material-warehouse-stored', loadChildren:()=>import('./pages/material-warehouse-stored/material-warehouse-stored.module').then(x=>x.MaterialWarehouseStoredModule), canActivate: [AppPermissionGuard] },

  { path: 'rmr-re-location', loadChildren:()=>import('./pages/rmr-relocation/rmr-relocation.module').then(x=>x.RmrRelocationModule), canActivate: [AppPermissionGuard] },

  { path: 'material-request', loadChildren:()=>import('./pages/material-request-form/material-request-form.module').then(x=>x.MaterialRequestFormModule), canActivate: [AppPermissionGuard] },


  { path: 'export-surplus-list', loadChildren:()=>import('./pages/export-surpus-material/export-surpus-material.module').then(x=>x.ExportSurpusMaterialModule), canActivate: [AppPermissionGuard] },
  { path: 'unauthorized-application', component: UnauthorizedApplicationComponent },
  { path: 'unauthorized', component: UnauthorizedPageComponent },
  { path: '', redirectTo: '/home', pathMatch: 'prefix' },
  // { path: '**', redirectTo: '' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

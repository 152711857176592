import { AdalService } from "adal-angular4";
import { HttpService } from "../services/http.service";
import { StorageService } from "../services/storage.service";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export abstract class AuthProvider {
    abstract init();
    abstract login();
    abstract User: any;
    abstract IsAuthenticated: boolean;
    abstract AccessToken: string;
    abstract GraphToken();
    abstract searchByPeople();
    abstract clearCache(): void;
}

@Injectable({
    providedIn: 'root'
})
export class AzureADProvider implements AuthProvider {

    constructor(private adalService: AdalService,
        private httpService: HttpService,
        private storageService: StorageService) {

        // Logging = {
        //     level: 3,
        //     log: function (message) {
        //         // console.log(message);
        //     }
        // };
        this.init();
    }

    init() {
        Object.assign(environment.authConfig, { isAngular: true });
        //environment.authConfig.
        var config = environment.authConfig;
        // config['localLoginUrl'] =this.getCurrentRoute();


        this.adalService.init(config);
        //this.GraphToken();

    }
    refreshToken() {



        console.log('token', this.adalService.userInfo);

        //this.adalService.refreshDataFromCache();
    }
    login() {

        this.adalService.login();
    }
    get User() {
        //debugger
        if (this.IsAuthenticated) {
            return this.adalService.userInfo;
        } else {
            return null;
        }

    }

    get AccessToken(): string {
        if (this.IsAuthenticated) {
            return this.adalService.userInfo.token;
        }
        return "";

    }
    get IsAuthenticated(): boolean {
        this.handleWindowCallback();
        if (this.adalService.userInfo) {
            this.adalService.refreshDataFromCache();
            return this.adalService.userInfo.authenticated;
        } else {
            return false;
        }

    }


    GraphToken() {

        this.adalService.acquireToken('https://graph.microsoft.com').subscribe(token => {

            console.log('login to Azure');

            this.storageService.store('graphToken', token);
            return token;
        });
    }

    clearCache(): void {
        this.adalService.clearCache();
    }

    handleWindowCallback() {
        this.adalService.handleWindowCallback(false);
    }
    searchByPeople() { }
}

@Injectable({
    providedIn: 'root'
})
export class LDAPProvider implements AuthProvider {

    currentUserKey: string = ''
    constructor(
        private httpService: HttpService) {
        this.currentUserKey = 'currentuser'
    }
    init() {
        this.login()
    }
    login(): any {
        this.httpService.get('/api/user')
            .subscribe(data => {
                // this.IsAuthenticated = true
                sessionStorage.setItem(this.currentUserKey, JSON.stringify(data))
                return JSON.stringify(data) //this.User
            }, err => {
                // this.IsAuthenticated = false
                sessionStorage.removeItem(this.currentUserKey);
                throw err;
            });
    }
    get User(): any {
        let currentUser = JSON.parse(sessionStorage.getItem(this.currentUserKey))
        if (currentUser) {  // this.IsAuthenticated
            return currentUser
        } else {
            return null;
        }
    }
    // get User(): any {
    //     let currentUser = JSON.parse(sessionStorage.getItem(this.currentUserKey))
    //     if (currentUser) {  // this.IsAuthenticated
    //         return currentUser
    //     } else {
    //         return this.login()
    //     }
    // }

    AccessToken: string;

    get IsAuthenticated(): boolean {
        let currentUser = JSON.parse(sessionStorage.getItem(this.currentUserKey))
        if (currentUser) {  // this.IsAuthenticated
            return true
        } else {
            return false
        }
    }

    GraphToken() { }

    searchByPeople() { }

    clearCache(): void {
        throw new Error("Method not implemented.");
    }
}
import { Component, OnInit, OnDestroy, ErrorHandler, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, ActivatedRoute } from '@angular/router';
import { AppService } from './app.service';
import { Program, MenuBarApp, UserLogin } from './app.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './shared/services/storage.service';

export class Menu {
  MenuID: number
  MenuName: string
  Priority: number
  MenuPath: string
}

export class MenuHeader {
  MenuID: number
  Priority: number
  MenuName: number
  SubMenu: Menu[]
}



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, OnDestroy, ErrorHandler {
  handleError(error: any): void {
    console.log('error on app comp=>', error);
  }
  title = 'app';
  env = '';
  KeyVault_Db = "";
  MenuBarApp: MenuBarApp = new MenuBarApp()
  authUser: any;
  lengthProgram: number = 0;
  ProgramList = [];
  fakeLogin: string;
  deploymentDate: string;
  year: string;

  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService,
    private changeDetection: ChangeDetectorRef,
    private storageService: StorageService) {

    var ua = window.navigator.userAgent;
    var edge = ua.indexOf('Edge/');
    // console.log(ua, edge);
    if (edge <= 0) {
      this.openEdge();
    }

    this.SaveRedirectUrl();


    if (!this.authService.IsAuthenticated) {
      if (!localStorage.getItem("redirectUrl")) {
        localStorage.setItem("redirectUrl", this.getCurrentRoute());
      }

      this.authService.login();

    }
    else if (this.authService.AccessToken && this.authService.AccessToken != "") {
      this.LoginToApp();
    }


    // } else {
    //   window.open("microsoft-edge:" + document.URL, "_BLANK").close();
    // }

  }
  private getCurrentRoute() {
    var loginCallback = window.location.href;//sessionStorage['adal.login.request'];
    if (loginCallback) {
      var route = loginCallback.split('#')[1];
      return route;
    }
    return "";
  }
  refresh() {
    this.authService.refreshToken();
  }
  openEdge() {
    // window.location.href = "microsoft-edge:" + document.URL;
  }

  ngOnDestroy() {
    this.authService.clearCache();
  }

  ngOnInit() {
    
    // this.appService.DeploymentDate().subscribe(response => {
    //   this.deploymentDate = response;
    //   this.year = this.extractYear(this.deploymentDate);
    // });
    //this.authService.GraphToken();
  }

  ngAfterViewChecked() {
    this.changeDetection.detectChanges();
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
        }
      });
  }


  setDevelopHelp(payload) {
    //console.log(payload);
    if (payload.env) {
      this.env = "-" + payload.env;
      this.KeyVault_Db = payload['db-keyvault'];

    }
  }

  FakeLogin(loginId) {
    this.appService.FakeLogin(loginId).subscribe(userLogin => {
      this.keepCurrentLogin(userLogin);
    }, err => {
      this.gotoUnauthorized();

    });
  }
  keepCurrentLogin(user: UserLogin) {
    if (user) {
      localStorage.setItem("app.token", user.token);

      let helper = new JwtHelperService();
      var payload = helper.decodeToken(user.token);
      let ObjectData = JSON.parse(payload.UserData)
      if (ObjectData) {
        this.setDevelopHelp(payload)

        //localStorage.setItem("CurrentUser", payload.UserData);
        localStorage.setItem('CurrentUser', JSON.stringify(user));
        this.appService.CurrectUser = Object.assign({}, ObjectData)
        this.MenuBarApp = user.UserPermission;//ObjectData.UserPermission;
        this.lengthProgram = Math.round(this.MenuBarApp.Program.length / 2);
        let redirectUrl = localStorage.getItem("redirectUrl");
        if (redirectUrl) {
          this.router.navigateByUrl(`/${redirectUrl}`, { skipLocationChange: false });
          localStorage.removeItem("redirectUrl");
        }
      }
    } else {
      this.gotoUnauthorized();
    }
  }

  LoginToApp() {
    this.appService.Login().subscribe(userLogin => {
      this.keepCurrentLogin(userLogin);
    }, err => {
      this.gotoUnauthorized();

    });


  }

  gotoUnauthorized() {
    localStorage.setItem("app.token", null);
    this.authService.clearCache();
    this.router.navigate(['unauthorized-application']);
  }
  SaveRedirectUrl() {

    let curren_url = document.URL;
    curren_url = curren_url.replace('/#/', '/');
    var url = new URL(curren_url);
    var redirectUrlTo = url.searchParams.get("redirectUrl");
    if (redirectUrlTo) {
      localStorage.setItem("redirectUrl", redirectUrlTo);
    }

  }

  extractYear(dateString: string): string {
    const date = new Date(dateString);
    return date.getFullYear().toString();
  }

}



import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class LoadingService {
    private subjectLoad = new Subject<boolean>();
    private keepAfterRouteChange = false;
    public countLoaded: number = 0;

    constructor(private router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                }
            }
        });
       
    }
    

    getAlertLoad(): Observable<any> {
        return this.subjectLoad.asObservable();
    }

    showLoading(keepAfterRouteChange = false) {
        this.countLoaded++
        if (this.countLoaded === 1) {
            this.keepAfterRouteChange = keepAfterRouteChange;
            this.subjectLoad.next(<boolean>true);
           //console.log('-------- show_Loader => ', this.countLoaded);
        } else if (this.countLoaded < 0) {
            this.countLoaded = 0
           //console.log('show_Loader => ', this.countLoaded);
        } else {
           //console.log('-------- show_Loader => ', this.countLoaded);
        }

      
    }
    forceClosedLoading() {
        this.countLoaded = 0;
        this.subjectLoad.next(<boolean>false);
    }
    hideLoading(keepAfterRouteChange = false) {
        if (this.countLoaded > 0) {
            this.countLoaded--
            if (this.countLoaded === 0) {
                this.keepAfterRouteChange = keepAfterRouteChange;
                this.subjectLoad.next(<boolean>false);
               // console.log('-------- hide_Loader => ', this.countLoaded)
            } else {
               //console.log('hide_Loader => ', this.countLoaded)
            }
        } else {
            this.countLoaded = 0
           // console.log('** hide_Loader => ', this.countLoaded)
        } 

       
    }


}
export class Person {
  constructor( 
    public id: string, 
    public displayName: string,
    public givenName: string,
    public surname: string,  
    public employeeId: string,
    public department: string,
    public employeeType: string, 
    public officeLocation: string,
    public jobTitle: string,
    public companyName: string,
    public businessPhones: string[],
    public mobilePhone: string, 
    public userPrincipalName: string,
    public mail:string,
    public extension_39c7d3e68666465dab296ee0fc538118_sAMAccountName:string,
    ) {}
}



export class GraphAttributes {
  public static Cai : string = "extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11";
  public static LogonName = "extension_39c7d3e68666465dab296ee0fc538118_sAMAccountName";
  public static Oid = "id";
  public static staticUserPrincipalName = "userPrincipalName";
  public static AccountEnabled = "accountEnabled";
  public static UserType = "userType";
  public static BusinessPhones = "businessPhones";
  public static City = "city";
  public static CompanyName = "companyName";
  public static Country = "country";
  public static Department = "department";
  public static DisplayName = "displayName";
  public static GivenName = "givenName";
  public static JobTitle = "jobTitle";
  public static Mail = "mail";
  public static MobilePhone = "mobilePhone";
  public static OfficeLocation = "officeLocation";
  public static PostalCode = "postalCode";
  public static PreferredLanguage = "preferredLanguage";
  public static PreferredName = "preferredName";
  public static State = "state";
  public static StreetAddress = "streetAddress";
  public static Surname = "surname";

  public static Description = "description";
  public static MailNickname = "mailNickname";
  public static MailEnabled = "mailEnabled";
  public static SecurityEnabled = "securityEnabled";
}
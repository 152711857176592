import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FileUploader, ParsedResponseHeaders, FileItem, FileUploaderOptions } from "ng2-file-upload";
import { UploadFile } from "./upload-file-btn.model";
import { environment } from "../../../../environments/environment";
import { UploadFileBtnService } from "./upload-file-btn.service";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { LoadingService } from "../../services/loading.service";
import { AuthService } from "../../services/auth.service";


@Component({
    selector: 'upload-file-btn',
    templateUrl: './upload-file-btn.component.html',
    styleUrls: ['./upload-file-btn.component.css'],
})
export class UploadFileBtnComponent implements OnInit {
    @Output() responseData: EventEmitter<any[]> = new EventEmitter();
    @Output() responseError: EventEmitter<any[]> = new EventEmitter();
    @Input('URL') URL: string;
    @Input('FileTemplateName') FileTemplateName: string = null;
    @Input() showLoading: boolean = false;
    uploader: FileUploader;
    hasBaseDropZoneOver: boolean = false;
    AttachmentConfigs: any;
    nameFileIsSelect: string = '';
    public uploadedFiles: UploadFile[] = [];
    public deletedFilesTmp: any[] = [];
    public filesBeforeUpload: any[] = []
    appToken: string;
    @Input() CSSclassBTN = "btn-primary"
    @Input() stylesBTN = {}
    @Input() nameBTN: string = 'Browse'


    constructor(private UploadFileService: UploadFileBtnService
        , private loadingservice: LoadingService
        , private authService: AuthService, ) {
    }

    ngOnInit() {
        this.appToken = localStorage.getItem("app.token") ? localStorage.getItem("app.token") : '';
        this.uploadedFiles = [];
        // let queueLimit = 1;
        this.uploader = new FileUploader({
            // authToken: `Bearer ${this.authService.AccessToken}`,  
            isHTML5: true,
            // queueLimit: queueLimit,
            autoUpload: true,
            method: 'POST',
            url: environment.webApiUrl + this.URL,
            allowedMimeType: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
            // allowedMimeType: ["image/png"] // test
        });
        var uo: FileUploaderOptions = {url: environment.webApiUrl + this.URL,};
        uo.headers = [
            //{ name: 'x-ms-blob-type', value: 'BlockBlob' },
            { name: "Authorization", value: `Bearer ${this.authService.AccessToken}` },
            { name: "app-token", value: `${this.appToken}` }
        ]
        this.uploader.setOptions(uo);

        this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
        this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
        this.uploader.onWhenAddingFileFailed = (item, filter) => {
            let message = '';
            switch (filter.name) {
                case 'fileSize':
                    message = `The file '${item.name}' has ${this.formatBytes(item.size)} that exceeds the maximum allowed size of ${this.AttachmentConfigs.maxSizeMB} MB`;
                    break;
                case 'mimeType':
                    message = `The file '${item.name}' (file type : '${item.type === '' ? 'unknown' : item.type}') is not allow to upload.Please contact administrator to allow this file type.`;
                    break;
                default:
                    message = 'Error when trying to upload file';
                    break;
            }
            console.log('upload fail => ', message)
            alert(message);
        };
        this.uploader.onAfterAddingFile = (item: FileItem) => {
            this.nameFileIsSelect = item.file.name;
            if (this.showLoading)
                this.loadingservice.showLoading();
            if (this.uploader.queue.length > 1) {
                this.uploader.removeFromQueue(this.uploader.queue[0])
            }
            this.filesBeforeUpload.push(item)
        }
    }

    private onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        console.log('success');
        if (this.showLoading)
            this.loadingservice.hideLoading();
        this.responseData.emit(JSON.parse(response));
    }

    private onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let error = JSON.parse(response); //error server response
        console.log(error);
        if (this.showLoading)
            this.loadingservice.hideLoading();
        this.uploader.removeFromQueue(item);
        this.responseError.emit(JSON.parse(response));

    }

    private formatBytes(bytes, decimals?) {
        if (bytes == 0) return '0 Bytes';
        const k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
        if (this.hasBaseDropZoneOver) {
        }
    }

    public DownloadTemplate() {
        if (this.FileTemplateName) {
            this.UploadFileService.DownloadFileTemplate(this.FileTemplateName).subscribe(res => {
                if (res) {
                    saveAs(res, "TemplateForMultipleItems.xlsx");
                }
            });
        }
    }

    changeBackground() {
        return "background-color: red;";

    }


}


import * as jQeury from '../../../../assets/js/jquery-3.7.1.js';
import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl, NgForm, FormGroup } from '@angular/forms';

@Component({
  selector: 'show-errors',
  templateUrl: 'show-errors.component.html'
})

export class ShowErrorsComponent {

  private static readonly errorType = {
    'required': () => 'This field is required',
    'minlength': (params) => 'The min number of characters is ' + params.requiredLength,
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'pattern': (params) => 'The required pattern is: ' + params.requiredPattern,
    'description': (param) => param
  }
  private animationDuration: number = 500
  errorList: string[] = []

  public ValidateForms(mainForm: NgForm, customErrors: string[]) {
    // debugger
    this.showErrorContainer(this.getControlError(mainForm['controls']), customErrors)
  }

  hideErrorContainer() {
    setTimeout(() => {
      this.errorList = []
    }, this.animationDuration);
    jQeury('#alertBox').closest(".alert-shim").slideUp(this.animationDuration)
  }

  private showErrorContainer(validateErrorList: string[], customErrors: string[]) {
    if ((customErrors && customErrors.length > 0) || (validateErrorList && validateErrorList.length > 0)) {
      this.errorList = customErrors.concat(validateErrorList)
      setTimeout(() => {
        jQeury('#alertBox').slideDown(this.animationDuration)
      }, 50);
    }
  }

  getControlError(ctrls: Object): string[] {
    // debugger
    let results: string[] = []
    let childForms = ctrls['CHILD_FORMS']
    let formGroups = childForms['controls']

    if (formGroups.length <= 0) {
      Object.getOwnPropertyNames(ctrls).forEach(propName => {
        if (propName && propName != 'CHILD_FORMS') {
          let control = ctrls[propName]
          if (control.invalid) {
            results.push(propName + " : " + this.getControlErrorMessage(control, propName))
          }
        }
      })
      return results;
    } else {
      formGroups.forEach(fg => {
        if (fg.invalid && (fg.dirty || fg.touched)) {
          results = results.concat(this.getControlError(fg['controls']))
        }
      })
      return results
    }
  }

  private getControlErrorMessage(control: AbstractControl, propName: string): any {
    let result = Object.keys(control.errors)
      .map(field => ShowErrorsComponent.errorType[field](control.errors[field]))
    return result
  }


}


















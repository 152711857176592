export class UserDTO {
  Id: number;
  constructor(
    public sAMAccountName: string,
    public Fullname: string,
    public Email: string,
    public JobTitle: string,
    public Department: string,
    public Company: string,
    public FirstName: string,
    public LastName: string,
    public UserPrincipalName: string) { }

}
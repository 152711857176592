import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { ErrorsService } from './errors.service';
import { StorageService } from './storage.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    constructor(private injector: Injector, 
                private location: Location,
                private storageService: StorageService) { }    

    handleError(error: any) {
        let router = this.injector.get(Router);
        console.log('URL: ' + router.url);
        console.log('error: ' , error);
        const alertService = this.injector.get(AlertService);
        const errorsService = this.injector.get(ErrorsService);
        
        if (error instanceof HttpErrorResponse) {
            // Server or connection error happened
            if (!navigator.onLine) {
              // Handle offline error
              return alertService.error('Network error, please check your connection and retry again');
            } else {
              // Handle Http Error (error.status === 403, 404...)
            //   errorsService.log(error)
            //                .subscribe();
              return alertService.error("An occurred error, please try again or contact your administrator.");
            }
         } else {
           // Handle Client Error (Angular Error, ReferenceError...)   
           const appId = 'FEMBA';
           const time = new Date().getTime();
           const id = `${appId}-${time}`;

           const errorToPost = { name : error.name , message : error.message , stack : error.stack };

           this.storageService.store(id, errorToPost);
            /*
           errorsService.log(errorToPost)
                        .subscribe(); 
                        */
            return alertService.error("An error occurred, please try again.");
         }
    }
} 
import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppService } from "./app.service";
import { Menu, MenuBarApp } from "./app.model";
import { AuthService } from "./shared/services/auth.service";

@Injectable()
export class AppPermissionGuard implements CanActivate {

  constructor(private appService: AppService,
    private router: Router,
    private authService: AuthService, ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let url = state.url;
    if (url == '/' || url == "" || !url || url == '/home'|| url == '/master')
      return true;// this.router.navigate(['home']);
      
    url = url.split('/')[1];
    url = url.split('?')[0];
    if(url=="master"){
      url = state.url;
    }else{
      url = '/' + url;
    }
   
    let menu = this.GetMenuPermision(url);
    // console.log(menu)
    if (!menu)
      this.router.navigate(['unauthorized-application']);

    this.appService.PermissionMenu = Object.assign({}, menu);
    return true;
  }


  private GetMenuPermision(url: string): Menu {
    var appToken = localStorage.getItem("app.token") ? localStorage.getItem("app.token") : '';
    let menu = null;
    if (this.authService.AccessToken && this.authService.AccessToken != "" && appToken) {
      this.appService.PermissionMenu = null;
      let CurrentUser = localStorage.getItem("CurrentUser") ? localStorage.getItem("CurrentUser") : '';
      let ObjectData: MenuBarApp = JSON.parse(CurrentUser).UserPermission;
      if (ObjectData && ObjectData.Program) {
        let pro = ObjectData.Program.filter(p => p.Menu.filter(m => m.MenuPath == url).length > 0);
        menu = pro && pro[0] && pro[0].Menu && pro[0].Menu[0] ? pro[0].Menu[0] : null;
      }
    }
    return menu;
  }
}
import { AzureADProvider } from './../models/auth-provider.model';
import { Component, Injectable } from '@angular/core';

// import '../../../../node_modules/msal/dist/msal.js';
// /// <reference path="../../../../node_modules/msal/dist/msal.d.ts" />
// import * as Msal from 'msal';



import { Observable } from 'rxjs';
import '../rxjs/rxjs-operators';

import { environment } from '../../../environments/environment';
//import { AdalService } from 'adal-angular4';
import { StorageService } from './storage.service';
import { HttpService } from './http.service';
// import { AuthProvider, AzureADProvider } from '../models/auth-provider.model';
import { AuthProvider } from '../models/auth-provider.model';
// import { MsalService } from 'msal-angular';

// const CONFIG = environment.authConfig;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private authProvider: AzureADProvider) {

  }

  init() {

    this.authProvider.init();
  }

  login() {
    this.authProvider.login();
  }
  refreshToken() {
    this.authProvider.refreshToken();


  }
  handleWindowCallback() {
    this.authProvider.handleWindowCallback();
  }
  get User() {
    return this.authProvider.User
  }

  get AccessToken(): string {
    return this.authProvider.AccessToken;

  }

  GraphToken() {
    return this.authProvider.GraphToken();
  }

  clearCache() {
    this.authProvider.clearCache();
  }
  get IsAuthenticated(): boolean {
    return this.authProvider.IsAuthenticated;
  }

}
